import React from "react";
import { useFlagStore } from "../store/useFlagStore";
import { useWatchList } from "../store/useWatchListStore";
import { Menu, MenuItem } from "@mui/material";
import { useShallow } from "zustand/react/shallow";
import { useSnackStore } from "../../src/store";

interface SymbolListMenuProps {
  parentId: string;
  ticker: string;
  onClose: () => void;
  onFlagList: () => void;
  onUnFlagList: () => void;
  type: "watchList" | "scan";
}

export const SymbolListMenu: React.FC<SymbolListMenuProps> = ({
  parentId,
  ticker,
  onClose,
  onFlagList,
  onUnFlagList,
  type,
}) => {
  const hasTicker = ticker.length > 0;

  const [ToggleFlag, IsTickerFlagged, ClearAllFlags] = useFlagStore(
    useShallow((s) => [s.ToggleFlag, s.IsTickerFlagged, s.ClearAllFlags])
  );

  const setSnack = useSnackStore((s) => s.SetSnackMessage);
  const isFlagged = hasTicker && IsTickerFlagged(ticker);
  const [
    watchLists,
    patchWatchListItem,
    deleteWatchListItem,
    clearWatchList,
    deleteWatchList,
    copyWatchListFlagged,
    copiedLength,
    pasteCopied,
  ] = useWatchList(
    useShallow((s) => [
      s.watchLists,
      s.patchWatchListItem,
      s.deleteWatchListItem,
      s.clearWatchList,
      s.deleteWatchList,
      s.copyWatchListFlagged,
      s.copiedSymbols.length,
      s.pasteCopied,
    ])
  );

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLLIElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const watchListOptions = Object.values(watchLists ?? {}).map(
    (item, index) => (
      <MenuItem
        key={item.id && index}
        onClick={() => {
          patchWatchListItem(item.id as string, ticker);
          handleClose();
          onClose();
        }}
      >
        {item.name}
      </MenuItem>
    )
  );

  const handleClearWatchList = async (id: string) => {
    await clearWatchList(id);
  };

  const copyFlaggedTickers = async (id: string) => {
    await copyWatchListFlagged(id);
  };

  const copyToClipboard = async () => {
    const allTickers =
      watchLists?.[parentId]?.symbols
        .map((symbol) => symbol.ticker)
        .join(" ") || "";
    navigator.clipboard
      .writeText(allTickers)
      .then(() => {
        setSnack("Watchlist tickers copied to clipboard");
      })
      .catch((err) => {
        setSnack("Failed to copy watchlist tickers: ", "error");
      });
  };

  let watchListMenu: React.JSX.Element[] = [];

  if (type === "watchList") {
    watchListMenu = [
      <MenuItem
        key="copyWatchList"
        onClick={() => {
          copyToClipboard();
          onClose();
        }}
      >
        Copy to Clipboard
      </MenuItem>,
      <MenuItem
        key="copyWatchFlagged"
        onClick={() => {
          copyFlaggedTickers(parentId);
          onClose();
        }}
      >
        Copy List Flagged
      </MenuItem>,
      <MenuItem
        key="clearwatchlist"
        onClick={() => {
          handleClearWatchList(parentId);
          onClose();
        }}
      >
        Clear Watchlist
      </MenuItem>,
      <MenuItem
        key="deletewatchlist"
        onClick={() => {
          deleteWatchList(parentId);
          onClose();
        }}
      >
        Delete Watchlist
      </MenuItem>,
    ];
  }

  if (copiedLength > 0) {
    watchListMenu.unshift(
      <MenuItem
        key="pasteWatchFlagged"
        onClick={() => {
          pasteCopied(parentId);
          onClose();
        }}
      >
        Paste Copied
      </MenuItem>
    );
  }

  let sharedMenu: React.JSX.Element[] = [];

  if (hasTicker) {
    sharedMenu = [
      <MenuItem
        key="flagorremove"
        onClick={() => {
          ToggleFlag(ticker);
          onClose();
        }}
      >
        {isFlagged ? "Remove Flag" : "Flag Ticker"}
      </MenuItem>,
      <MenuItem key="addtolist" onClick={handleClick}>
        Add to watchlist
      </MenuItem>,
    ];

    if (type === "watchList") {
      sharedMenu.push(
        <MenuItem
          key="removefromlist"
          onClick={() => {
            deleteWatchListItem(parentId, ticker);
            onClose();
          }}
        >
          Remove from List
        </MenuItem>
      );
    }
  }

  return (
    <>
      {sharedMenu}
      <MenuItem
        key="flaglist"
        onClick={() => {
          onFlagList();
          onClose();
        }}
      >
        Flag List
      </MenuItem>
      <MenuItem
        key="removeflags"
        onClick={() => {
          onUnFlagList();
          onClose();
        }}
      >
        Unflag List
      </MenuItem>
      <MenuItem
        key="clearallflags"
        onClick={() => {
          ClearAllFlags();
          onClose();
        }}
      >
        Clear All Flags
      </MenuItem>
      <hr />
      {watchListMenu}
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {watchListOptions}
      </Menu>
    </>
  );
};
