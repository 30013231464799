import { useState } from "react";
import { Button, TextField, Paper, Box, Typography } from "@mui/material";
import axios from "../api/axios-wrapper";
import { AuthModal } from "../auth/authModal";

export const RefreshTicker = () => {
  const [ticker, setTicker] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState("");

  const handleRefresh = async () => {
    if (!ticker) {
      setMessage("Please enter a ticker");
      return;
    }

    setIsLoading(true);
    setMessage("");

    try {
      const response = await axios.get(`/admin/refreshDaily?ticker=${ticker}`);
      if (response.status === 200) {
        setMessage(`Successfully refreshed data for ${ticker}`);
        setTicker("");
      } else {
        setMessage(`Error: failed`);
      }
    } catch (error) {
      setMessage(`Error: ${error}`);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <AuthModal>
      <Typography variant="h5" gutterBottom>
        Refresh Ticker Data
      </Typography>
      <Box sx={{ display: "flex", gap: 2, mb: 2 }}>
        <TextField
          fullWidth
          label="Ticker Symbol"
          value={ticker}
          onChange={(e) => setTicker(e.target.value.toUpperCase())}
          disabled={isLoading}
        />
        <Button
          variant="contained"
          onClick={handleRefresh}
          disabled={isLoading}
        >
          {isLoading ? "Refreshing..." : "Refresh"}
        </Button>
      </Box>
      {message && (
        <Typography
          color={message.startsWith("Error") ? "error" : "success"}
          sx={{ mt: 2 }}
        >
          {message}
        </Typography>
      )}
    </AuthModal>
  );
};
