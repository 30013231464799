import { useEventBusStore } from "../store/useEventBus";

export const EVENTS = {
  LOGOUT: "Logout",
  LOGGEDOUT: "LoggedOut",
};

const eventEmitter = {
  emit: (event: string, data?: any) => {
    useEventBusStore.getState().emitEvent(event, data);
  },
  on: (event: string, callback: (data?: any) => void) => {
    const listener = (e: string, d: any) => {
      if (e === event) {
        callback(d);
      }
    };
    useEventBusStore.getState().addListener(listener);
    return () => useEventBusStore.getState().removeListener(listener);
  },
  removeListener: (event: string, callback: (data?: any) => void) => {
    useEventBusStore.getState().removeListener((e, d) => {
      if (e === event) {
        callback(d);
      }
    });
  },
  // Add off as an alias to removeListener for compatibility
  off: (event: string, callback: (data?: any) => void) => {
    useEventBusStore.getState().removeListener((e, d) => {
      if (e === event) {
        callback(d);
      }
    });
  },
};

export default eventEmitter;
