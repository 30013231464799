import { useContext, useEffect, useRef, useState } from "react";
import { ContextMenu, TopMenu } from "./menus/";

import AddBoxIcon from "@mui/icons-material/AddBox";
import FunctionsIcon from "@mui/icons-material/Functions";
import { useAppStore, useFormulaStore, useWatchList } from "./store";
import { addContainer } from "./helpers/layoutHelper";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { useNavigate } from "react-router-dom";
import { APPROUTES } from "./appRoutes";
import { AuthContext } from "./auth";
import NoAccountsIcon from "@mui/icons-material/NoAccounts";
import { MenuItem } from "@mui/material";
import axios from "./api/axios-wrapper";

export const AppHeader = () => {
  const leftMenuRef = useRef<HTMLSpanElement>(null);
  const rightMenuRef = useRef<HTMLSpanElement>(null);

  const authContext = useContext(AuthContext);

  // Check if authContext is undefined (not wrapped in AuthProvider)
  if (!authContext) {
    return <div>Loading...</div>; // or handle this case as needed
  }

  const { isLoggedIn, authToken } = authContext;

  const ActiveSymbol = useAppStore((state) => state.ActiveSymbol);
  const watchLists = useWatchList((s) => s.watchLists);
  // const { SetFormulaModalOpen  = useFormulaStore((s)=>s.SetFormulaModalOpen);

  const [showContextMenu, setShowContextMenu] = useState<boolean>(false);
  const [userMenuOpen, setUserMenuOpen] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const getData = async () => {};

    getData();
  }, []);

  var leftPosition = { x: 0, y: 0 };
  var rightPosition = { x: 0, y: 0 };

  if (leftMenuRef.current) {
    const rect = leftMenuRef.current.getBoundingClientRect();
    leftPosition = {
      y: rect.top + window.scrollY + 10,
      x: rect.left + window.scrollX + 10,
    };
  }

  if (rightMenuRef.current) {
    const rect = rightMenuRef.current.getBoundingClientRect();
    rightPosition = {
      y: rect.top + window.scrollY - 10,
      x: rect.left + window.scrollX + 10,
    };
  }

  const isAdmin = authToken?.roles.includes("SuperAdmin");

  const refreshData = async () => {
    await axios.get("/admin/refreshData");
  };

  const addWatchList = () => {
    if (!watchLists || Object.keys(watchLists).length === 0) {
      navigate(APPROUTES.APP.ADD_WATCHLIST);
    } else {
      addContainer(
        watchLists[Object.keys(watchLists)[0]].id as string,
        "watchList"
      );
    }
  };

  return (
    <span className="app_header_menu">
      <span style={{ display: "flex", alignItems: "center", gap: "4px" }}>
        <span style={{ display: "flex" }} ref={leftMenuRef}>
          <AddBoxIcon
            fontSize="inherit"
            onClick={() => setShowContextMenu(true)}
          />
        </span>
        <ContextMenu
          open={showContextMenu}
          position={leftPosition}
          onClose={() => setShowContextMenu(false)}
        >
          <TopMenu
            addWatchList={addWatchList}
            onClose={() => {
              setShowContextMenu(false);
            }}
          ></TopMenu>
        </ContextMenu>
        {/* <FunctionsIcon
          fontSize="inherit"
          onClick={() => SetFormulaModalOpen(true)}
        /> */}
        {ActiveSymbol}
      </span>
      {!isLoggedIn && (
        <span onClick={() => navigate(APPROUTES.APP.LOGIN)}>
          <NoAccountsIcon fontSize="small" />
        </span>
      )}

      {isLoggedIn && (
        <>
          <span
            style={{ color: "green" }}
            onClick={() => setUserMenuOpen(!userMenuOpen)}
            ref={rightMenuRef}
          >
            <AccountCircleIcon fontSize="small" />
          </span>
          <ContextMenu
            open={userMenuOpen}
            position={rightPosition}
            onClose={() => setUserMenuOpen(false)}
          >
            <MenuItem onClick={() => navigate(APPROUTES.APP.LOGOUT)}>
              Logout
            </MenuItem>
            {isAdmin && (
              <>
                <MenuItem
                  onClick={() => navigate(APPROUTES.APP.ADMIN_REFRESH_TICKER)}
                >
                  Refresh Ticker
                </MenuItem>
                <MenuItem onClick={() => refreshData()}>Refresh Data</MenuItem>
              </>
            )}
          </ContextMenu>
        </>
      )}
    </span>
  );
};
