import { useEffect, useState } from "react";
import { mappedCompProps } from "../app";
import { Picker, PickerOption } from "../shared/picker";
import { useAppStore, useWatchList } from "../store/index";
import { MenuHeader } from "./menu-header";
import { SymbolListComponent } from "./symbol-list";
import { WatchList } from "./symbol-list-interface";
import "./watch-list.scss";
import { useNavigate } from "react-router-dom";
import { useShallow } from "zustand/react/shallow";

const ADD_NEW_WATCHLIST = "add_new_watchlist";

export const WatchListComponent = ({
  Id,
  remove,
  isActive,
  selectedId,
}: mappedCompProps) => {
  const watchLists = useWatchList((s) => s.watchLists);
  const [selectedWatchListId, setSelectedWatchListId] = useState<string | null>(
    null
  );
  const [options, setOptions] = useState<PickerOption[]>([]);
  const navigate = useNavigate();
  const [ActiveLayout, UpsertLayout] = useAppStore(
    useShallow((s) => [s.ActiveLayout, s.UpsertLayout])
  );

  var watchList: WatchList | null = null;
  if (watchLists !== null && selectedWatchListId !== null) {
    watchList = watchLists[selectedWatchListId];
  }

  useEffect(() => {
    if (selectedId) {
      setSelectedWatchListId(selectedId);
    }
  }, [selectedId]);

  useEffect(() => {
    if (!watchLists) {
      return;
    }

    const pickerOptions: PickerOption[] = Object.values(watchLists).map(
      (item: WatchList) => ({
        value: item.id as string,
        label: item.name,
      })
    );
    const empty = {
      value: "",
      label: "",
    };
    const addNew = {
      value: ADD_NEW_WATCHLIST,
      label: "Create New",
    };
    setOptions([empty, addNew, ...pickerOptions] as PickerOption[]); //setOptions(pickerOptions);
  }, [watchLists]);

  const handleChange = (event: any) => {
    var val = event.target.value;
    if (val === ADD_NEW_WATCHLIST) {
      //ADD_WATCHLIST
      navigate("/app/add-watchlist/false");
      return;
    }

    var al = ActiveLayout;
    var d = al?.containers.find((f) => f.i === Id);
    if (al && d) {
      d.selectedId = val;
      UpsertLayout(al, true);
    }

    setSelectedWatchListId(val);
  };

  const dropDown = () => {
    return (
      <Picker
        key="watchPicker"
        options={options}
        selectedValue={selectedWatchListId ?? ""}
        handleChange={handleChange}
      ></Picker>
    );
  };

  const handleCreated = (guid: string) => {
    //TODO better way to do this
    var _layout = ActiveLayout;
    if (_layout != null) {
      var currContainer = _layout.containers.find((f) => f.i === Id);
      if (currContainer !== undefined) {
        currContainer.selectedId = guid;
        //  UpsertLayout(_layout);
      }
    }

    setSelectedWatchListId(guid);
  };

  return (
    <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
      <MenuHeader
        key={selectedWatchListId}
        type="watchList"
        containerId={Id}
        display={dropDown()}
        remove={remove}
        add={
          watchList && selectedWatchListId
            ? // ADD_WATCHLIST_TICKER
              () => navigate("/app/add-watchlist-ticker/" + selectedWatchListId)
            : undefined
        }
      />
      <div id="scrollable-container" className="scrollable-container">
        <div className="content">
          <SymbolListComponent
            type="watchList"
            parentId={selectedId}
            isActive={isActive}
            items={watchList ? watchList.symbols : []}
          />
        </div>
      </div>
    </div>
  );
};
