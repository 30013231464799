import { NumberSize, Resizable } from "re-resizable";
import { Direction } from "re-resizable/lib/resizer";
import React, { useEffect, useRef, useState } from "react";
import * as ReactDOM from "react-dom/client";
import { ContextMenu, SymbolListMenu } from "../menus/";
import { useAppStore, useFlagStore, useWatchList } from "../store/";
import { SvgFlag } from "../svgs/flag";
import { Symbols } from "./symbol-list-interface";
import "./symbol-list.scss";
import { useShallow } from "zustand/react/shallow";
import { ContentCopy } from "@mui/icons-material";

const columns = [
  { index: 0, disp: "Flag", val: "isFlagged", type: "svg" },
  { index: 1, disp: "Symbol", val: "ticker", type: "string" },
  { index: 2, disp: "Price", val: "price", type: "string" },
  { index: 3, disp: "Change", val: "change", type: "string" },
];

interface ColumnSizes {
  [key: number]: string;
}

export type SortOrder = "asc" | "desc";

const PriceComponent = (symbol: string) => <>2.75</>;
const StringComponent = (val: any) => {
  return <>{val.val}</>;
};

const componentMap: { [key: string]: React.ComponentType<any> } = {
  string: StringComponent,
  svg: SvgFlag,
  price: PriceComponent,
};

interface SymbolProps {
  parentId: string;
  items: Symbols[];
  isActive: boolean;
  type: "scan" | "watchList";
}

interface SortState {
  column: string;
  order: SortOrder;
}

export const SymbolListComponent = ({
  parentId,
  items,
  isActive = false,
  type = "scan",
}: SymbolProps) => {
  const [SetActiveSymbol, ActiveSymbol] = useAppStore(
    useShallow((s) => [s.SetActiveSymbol, s.ActiveSymbol])
  );
  const [FlaggedTickers, IsTickerFlagged, AddFlag, RemoveFlag, ToggleFlag] =
    useFlagStore(
      useShallow((s) => [
        s.FlaggedTickers,
        s.IsTickerFlagged,
        s.AddFlag,
        s.RemoveFlag,
        s.ToggleFlag,
      ])
    );

  const patchWatchListItem = useWatchList((s) => s.patchWatchListItem);

  const [itemsState, setItemsState] = useState<any[]>([]);
  const [columnSizes, setColumnSizes] = useState<ColumnSizes>({
    1: "50px",
    2: "50px",
  });
  const [index, setIndex] = useState(-1);
  const [sortState, setSortState] = useState<SortState>({
    column: "ticker",
    order: "desc",
  });
  const [contextMenuPosition, setContextMenuPosition] = useState<{
    x: number;
    y: number;
  }>({ x: 0, y: 0 });
  const [showContextMenu, setShowContextMenu] = useState<boolean>(false);
  const [selectedTicker, setSelectedTicker] = useState<string>("");

  const listRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLTableElement>(null);

  useEffect(() => {
    var itemsTransformed: any[] = items?.map((f) => {
      return {
        ...f,
        isFlagged: IsTickerFlagged(f.ticker),
      };
    });
    setItemsState(itemsTransformed);
  }, [items, FlaggedTickers]);

  useEffect(() => {
    if (itemsState.length == 0) return;
    var _idx = index > itemsState.length - 1 ? 0 : index;
    //todo why do we end up with this... seems like this shoudl be rewritten.
    if (_idx === -1) return;
    if (itemsState.length > _idx) {
      SetActiveSymbol(itemsState[_idx].ticker);
      scrollSelectedRowIntoView();
    }
  }, [index]);

  const scrollSelectedRowIntoView = () => {
    if (index !== -1 && containerRef.current) {
      const selectedRow = containerRef.current.querySelector(
        //need to skip header <TH>
        `tr:nth-child(${index + 2})`
      );
      if (selectedRow) {
        // selectedRow.scrollIntoView({
        //   behavior: "smooth",
        //   block: "center",
        //   // inline: "nearest",
        // });
      }
    }
  };

  const updateActiveIndex = (_items: any[]) => {
    var idx = _items?.findIndex((f) => f.ticker === ActiveSymbol);
    setIndex(idx);
  };

  useEffect(() => {
    updateActiveIndex(itemsState);
  }, [ActiveSymbol]);

  const checkForSpacebar = (e: KeyboardEvent) => {
    if (e.code === "Space") {
      e.preventDefault();
      setIndex((prev) => {
        const newIndex = prev + 1;
        return newIndex;
      });
    }
  };

  useEffect(() => {
    isActive && document.addEventListener("keydown", checkForSpacebar);

    return () => {
      document.removeEventListener("keydown", checkForSpacebar);
    };
  }, [isActive]);

  const handleDragStart = (
    e: React.DragEvent<HTMLTableRowElement>,
    item: any
  ) => {
    e.dataTransfer.setData(
      "application/json",
      JSON.stringify({
        ticker: item.ticker,
        sourceParentId: parentId,
      })
    );
    // Set source parent ID on the drag event
    e.dataTransfer.effectAllowed = "move";
    if (e.currentTarget) {
      e.currentTarget.setAttribute("data-source-parent", parentId);
    }

    // Create a custom drag image using Material UI icon
    const dragIcon = document.createElement("div");
    dragIcon.style.position = "fixed";
    dragIcon.style.top = "-1000px";
    dragIcon.style.backgroundColor = "#fff";
    dragIcon.style.padding = "8px";
    dragIcon.style.borderRadius = "4px";
    dragIcon.style.boxShadow = "0 2px 4px rgba(0,0,0,0.2)";
    dragIcon.style.fontFamily = "Roboto, sans-serif";
    dragIcon.style.fontSize = "14px";
    dragIcon.style.fontWeight = "500";

    // Render the ticker symbol into the div
    const root = ReactDOM.createRoot(dragIcon);
    root.render(
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: "6px",
          color: "#1976d2",
        }}
      >
        <ContentCopy style={{ fontSize: 16 }} />
        <span>{item.ticker}</span>
      </div>
    );

    // Add to document temporarily
    document.body.appendChild(dragIcon);

    // Set as drag image
    e.dataTransfer.setDragImage(dragIcon, 15, 15);

    // Clean up after a short delay
    setTimeout(() => {
      document.body.removeChild(dragIcon);
    }, 0);
  };

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();

    if (type === "scan") {
      return;
    }
    // Find the dragged element by its data attribute
    const draggedElement = document.querySelector("[data-source-parent]");
    if (draggedElement) {
      const sourceParentId = draggedElement.getAttribute("data-source-parent");
      if (sourceParentId !== parentId) {
        switchDrag(true);
      }
    }
  };

  const handleDragEnd = (e: React.DragEvent<HTMLTableRowElement>) => {
    // Clean up the data attribute
    if (e.currentTarget) {
      e.currentTarget.removeAttribute("data-source-parent");
    }
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    switchDrag(false);

    try {
      const data = JSON.parse(e.dataTransfer.getData("application/json"));
      const sourceParentId = e.dataTransfer.getData("text/parent-id");
      if (sourceParentId !== parentId) {
        patchWatchListItem(parentId, data.ticker);
      }
    } catch (err) {
      console.error("Failed to process dropped item:", err);
    }
  };

  const handleDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    switchDrag(false);
  };

  const switchDrag = (on: boolean) => {
    if (listRef.current) {
      on
        ? listRef.current.classList.add("drag-over")
        : listRef.current.classList.remove("drag-over");
    }
  };

  const handleRowRightClick = (
    event: React.MouseEvent<HTMLTableRowElement>,
    ticker: string
  ) => {
    event.preventDefault(); // Prevent default browser context menu
    const listRect = listRef.current?.getBoundingClientRect(); // Get the bounding rectangle of the list container
    const container = findClosestContainer(
      event.target as HTMLElement,
      "scrollable-container"
    ); // Find the closest scrollable container
    if (listRect && container) {
      const offsetX = event.clientX;
      const offsetY = event.clientY;
      setContextMenuPosition({ x: offsetX, y: offsetY });
      setShowContextMenu(true);
      setSelectedTicker(ticker);
    }
  };

  const findClosestContainer = (
    element: HTMLElement,
    containerId: string
  ): HTMLElement | null => {
    let currentElement: HTMLElement | null = element;
    while (currentElement && currentElement !== document.body) {
      if (currentElement.id === containerId) {
        return currentElement;
      }
      currentElement = currentElement.parentElement;
    }
    return null;
  };

  const closeContextMenu = () => {
    setShowContextMenu(false);
  };

  const handleResizeStop =
    (column: number) =>
    (
      event: MouseEvent | TouchEvent,
      direction: Direction,
      elementRef: HTMLElement,
      delta: NumberSize
    ) => {
      setColumnSizes({
        ...columnSizes,
        [column]: elementRef.style.width,
      });
    };

  const flagList = () => {
    //TODO does this cause a lot of renders?
    //better way?
    itemsState.forEach((item) => {
      AddFlag(item.ticker);
    });
  };

  const unFlagList = () => {
    itemsState.forEach((item) => {
      RemoveFlag(item.ticker);
    });
  };

  function sortByPropertyIndex(array: any[], sort: SortState) {
    const sortted = array.slice().sort((a, b) => {
      const aValue = a[sort.column];
      const bValue = b[sort.column];

      const result =
        typeof aValue === "number" && typeof bValue === "number"
          ? aValue - bValue
          : String(aValue).localeCompare(String(bValue));

      return sort.order === "asc" ? result : -result;
    });
    return sortted;
  }

  const handleSort = (columnName: string) => {
    var _sortState = sortState;
    if (_sortState.column === columnName) {
      _sortState.order = _sortState.order === "asc" ? "desc" : "asc";
    }

    _sortState.column = columnName;
    var sorted = sortByPropertyIndex(itemsState, _sortState);

    setSortState(_sortState);
    updateActiveIndex(sorted);
    setItemsState(sorted);
  };

  // Function to handle mouse leave on myDiv
  return (
    <div
      style={{ height: "100%" }}
      ref={listRef}
      className={showContextMenu ? "active-list" : ""}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
    >
      <table
        className="ticker-list"
        style={{ height: itemsState.length === 0 ? "100%" : "auto" }}
        ref={containerRef}
      >
        <thead>
          <tr>
            {columns.map((f, index) => {
              return (
                <th
                  key={`wl${f.index}`}
                  onClick={() => handleSort(f.val)}
                  style={{ cursor: "pointer" }}
                >
                  {
                    <Resizable
                      size={{ width: columnSizes[f.index], height: "auto" }}
                      onResizeStop={handleResizeStop(f.index)}
                      enable={{
                        top: false,
                        right: true,
                        bottom: false,
                        left: false,
                        topRight: false,
                        bottomRight: false,
                        bottomLeft: false,
                        topLeft: false,
                      }}
                    >
                      {f.disp}
                    </Resizable>
                  }
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {itemsState.length === 0 && (
            <tr
              style={{ height: "100%" }}
              onContextMenu={(e) => handleRowRightClick(e, "")}
            >
              <td colSpan={columns.length}></td>
            </tr>
          )}

          {itemsState.map((item, idx) => {
            const isActiveSymbol = item["ticker"] === ActiveSymbol;
            return (
              <tr
                key={`wl${idx}`}
                className={`${isActiveSymbol ? "symbol-active" : ""}`}
                onContextMenu={(e) => handleRowRightClick(e, item["ticker"])}
                draggable={true}
                onDragStart={(e) => handleDragStart(e, item)}
                onDragEnd={handleDragEnd}
              >
                {columns.map((column, index) => {
                  const ComponentType = componentMap[column.type];
                  const val = (item as any)[column.val];
                  const keyId = `${idx}_${index}`;

                  var handleClick: (val: string) => void = () =>
                    SetActiveSymbol(val);
                  var _class = "";
                  var props = {};

                  if (column.val === "isFlagged") {
                    handleClick = () => ToggleFlag(item["ticker"]);
                    _class = "flag-column";
                    props = { ...props, isFlagged: val };
                  } else if (column.val === "ticker") {
                    _class = "ticker-column";
                  }

                  return (
                    <td
                      className={_class}
                      onClick={() => handleClick(val)}
                      key={keyId}
                    >
                      {<ComponentType {...props} {...{ val }} />}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>

      <ContextMenu
        open={showContextMenu}
        position={contextMenuPosition}
        onClose={closeContextMenu}
      >
        <SymbolListMenu
          parentId={parentId}
          onClose={closeContextMenu}
          ticker={selectedTicker}
          onFlagList={flagList}
          onUnFlagList={unFlagList}
          type={type}
        />
      </ContextMenu>
    </div>
  );
};
