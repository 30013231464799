import React, { useEffect, useState } from "react";
import { SymbolListComponent, Symbols, MenuHeader } from "../Lists/index";
import { GetScanResultApi } from "../api/index";
import { mappedCompProps } from "../app";
import { LoadingIndicator } from "../shared/loadingindicator";
import { Picker, PickerOption } from "../shared/picker";
import { useScanStore } from "../store/index";
import { ScanItem } from "./scan-interfaces";

import "../Lists/watch-list.scss";
import { useShallow } from "zustand/react/shallow";

export const ScannerComponent = ({
  Id,
  remove,
  selectedId,
  isActive,
}: mappedCompProps) => {
  const [scanResult, setScanResult] = useState<Symbols[] | null>(null);
  const [isLoading, setLoading] = useState(false);
  const [getScanId, setScanId] = useState(selectedId);
  const [SetScanEditingId, GetScans] = useScanStore(
    useShallow((s) => [s.SetScanEditingId, s.GetScans])
  );

  const scans = GetScans;

  const openScanSettings = () => {
    SetScanEditingId(selectedId);
  };

  const getScanResult = (_id: string) => {
    setLoading(true);
    GetScanResultApi()
      .getScanResult(_id)
      .then((f) => {
        setScanResult(f as Symbols[]);
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    getScanResult(getScanId);
  }, [getScanId]);

  var scan: ScanItem | null = null;
  if (scans !== null) {
    scan = scans[selectedId];
  }

  if (!scan) {
    return;
  }

  const handleChange = (event: any) => {
    var val = event.target.value;
    setScanId(val);
    getScanResult(val);
  };

  const picOptions: PickerOption[] = [
    { value: "1_month_strongest", label: "1 month" },
    { value: "3_month_strongest", label: "3 month" },
    { value: "6_month_strongest", label: "6 month" },
    { value: "multi_gap", label: "Multi Gap" },
    { value: "strongest_today", label: "Strongest Today" },
    { value: "bullish_combo", label: "BullCombo" },
  ];

  const dropDown = () => {
    return (
      <Picker
        key="scanPick"
        options={picOptions}
        selectedValue={getScanId}
        handleChange={handleChange}
      ></Picker>
    );
  };

  return (
    <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
      <MenuHeader
        type="scan"
        containerId={Id}
        display={dropDown()}
        remove={remove}
        // settings={openScanSettings}
        refresh={() => getScanResult(getScanId)}
      />
      <div id="scrollable-container" className="scrollable-container">
        <div className="content">
          <LoadingIndicator isLoading={isLoading} />
          {scanResult && (
            <SymbolListComponent
              type="scan"
              parentId={getScanId}
              isActive={isActive}
              items={scanResult}
            />
          )}
        </div>
      </div>
    </div>
  );
};
