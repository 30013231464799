export const APPROUTES = {
  HOME: "/",
  APP: {
    ROOT: "/app",
    LOGIN: "/app/login",
    REGISTER: "/app/register",
    FORGOT_PASSWORD: "/app/forgot-password",
    RESET_PASSWORD: "/app/reset-password",
    CONFIRM_EMAIL: "/app/confirm-email",
    ADD_WATCHLIST: "/app/add-watchlist/:addWindow?",
    ADD_WATCHLIST_TICKER: "/app/add-watchlist-ticker/:id",
    MUST_LOGIN: "/app/must-login",
    LOGOUT: "/app/logout",
    ADMIN_REFRESH_TICKER: "/app/admin/refresh-ticker",
  },
};
