import React, { useContext } from "react";
import { addContainer } from "../helpers/layoutHelper";
import { AuthContext } from "../auth";
import { useNavigate } from "react-router-dom";
import { APPROUTES } from "../appRoutes";
import { MenuItem } from "@mui/material";

interface TopMenuProps {
  addWatchList: (open: boolean) => void;
  onClose: () => void;
}

export const TopMenu = ({ addWatchList, onClose }: TopMenuProps) => {
  const isLoggedIn = useContext(AuthContext)?.isLoggedIn;
  const navigate = useNavigate();

  const checkLogin = (): boolean => {
    if (!isLoggedIn) {
      navigate(APPROUTES.APP.MUST_LOGIN);
      return false;
    }
    return true;
  };

  const addChart = () => {
    checkLogin() &&
      addContainer(undefined, "chart", { ticker: "TSLA", timeFrame: "1day" });
    onClose();
  };

  const addScan = () => {
    checkLogin() && addContainer("6_month_strongest", "scan");
    onClose();
  };

  const addWorm = () => {
    checkLogin() && addContainer(undefined, "worm");
    onClose();
  };

  const addWatchlist = () => {
    checkLogin() && addWatchList(true);
    onClose();
  };

  return (
    <>
      <MenuItem onClick={addWatchlist}>Watchlist</MenuItem>
      <MenuItem onClick={addScan}>Scan</MenuItem>
      <MenuItem onClick={addChart}>Chart</MenuItem>
      <MenuItem onClick={addWorm}>Worm</MenuItem>
    </>
  );
};
