import { uniqueId } from 'lodash';
import GetDevData from './_forDev/devData';
import { Area } from './Area';
import { IModule } from './calculator/Modules';
import { createCanvas } from './ChartHelper';
import { CandleDataHelper, PointDataHelper, DebouncedResizeObserver } from './helpers';
import { LineData, Point, PointTypes } from './interfaces';
import { CallbackAction, CallbackData, CallbackManager, MouseManager } from './managers';
import { CrosshairRenderer, DrawnLineRenderer, MenuRenderer, OverlayRenderer } from './renderer';
import './styles.css';
import { XHelpers } from './XHelpers';
import logo from './static_assets/svg/double-right.svg';

export type TimePeriod = 'IntraDay' | 'Daily';

export interface ChartConfig {
  id?: string;
  showLegend: boolean;
  timePeriod: TimePeriod;
  elementSpacing: number;
  elementSize: number;
}

export interface LineString {
  startTime: string;
  startPrice: number;
  endTime: string;
  endPrice: number;
}

export type ChartDataTypes = PointTypes[] | PointTypes[][];

export class Chart {
  public handleMouseLeave = () => {
    document.body.style.cursor = 'default';
  };
  constructor(containerId: string, config: ChartConfig) {
    this.config = {
      id: uniqueId(),
      showLegend: config.hasOwnProperty('showLegend') ? config.showLegend : true,
      timePeriod: config.timePeriod || 'Daily',
      elementSpacing: config.elementSpacing || 2,
      elementSize: config.elementSize || 10,
    };

    this.parentDiv = document.getElementById(containerId) as HTMLDivElement;
    this.parentDiv.addEventListener('mouseleave', this.handleMouseLeave);

    let div = this.parentDiv.querySelector('out_of_range') as HTMLDivElement;
    if (!div) {
      div = document.createElement('div');
      div.id = 'out_of_range';
      div.onclick = () => {
        this.xHelper.SetOffsetX(0);
        this.RenderAll();
      };
      div.className = 'svg_wrapper';
      const imgElement = document.createElement('img');
      imgElement.className = 'svg_container svg_color';
      imgElement.src = logo;
      div.appendChild(imgElement);
      this.parentDiv.appendChild(div);
    }

    this.rightArrowDiv = div;

    this.overlayCanvas = createCanvas(this.parentDiv);
    this.overlayCtx = <CanvasRenderingContext2D>this.overlayCanvas.getContext('2d');

    this.overlayCanvas.style.zIndex = '10';

    this.xHelper = new XHelpers(
      this.config.elementSpacing,
      this.config.elementSize,
      this.overlayCanvas.width
    );
    var callback = new CallbackManager();
    this.menuRenderer = new MenuRenderer();

    var mouse = new MouseManager(
      this.xHelper,
      this.overlayCanvas,
      this.menuRenderer,
      this.GetChartArea,
      this.RenderAll,
      this.RenderOverlays
    );

    var over = new OverlayRenderer(
      this.xHelper,
      this.Areas,
      this.menuRenderer,
      this.overlayCtx,
      this.config,
      callback,
      mouse
    );
    const drawnLine = new DrawnLineRenderer(
      this.xHelper,
      this.Areas,
      this.overlayCtx,
      mouse,
      this.menuRenderer,
      callback
    );
    const crossHair = new CrosshairRenderer(
      this.overlayCtx,
      this.xHelper,
      this.Areas,
      this.menuRenderer,
      callback,
      config,
      mouse
    );

    over.addModule(drawnLine);
    over.addModule(crossHair);

    this.overlayModules.push(over);

    mouse.addMouseEvents(drawnLine);

    this.callbackManager = callback;
    this.drawnLine = drawnLine;

    this.overlayCtx.strokeStyle = 'black';

    this.debounceResize = new DebouncedResizeObserver((entries) => {
      for (const entry of entries) {
        this.resizeCanvas();
      }
    }, 300);

    this.debounceResize.observe(this.parentDiv);
  }

  DevData = (type: 'Line' | 'Candle') => {
    if (type === 'Candle') {
      GetDevData(new CandleDataHelper(), false, true, undefined, this.SetData);
    } else {
      GetDevData(new PointDataHelper(), true, true, undefined, this.SetData);
    }
  };

  xHelper: XHelpers;
  Areas: Area[] = [];
  // private plotCount = 0;
  private parentDiv: HTMLDivElement;
  private config: ChartConfig;
  private overlayModules: IModule[] = [];
  private overlayCtx: CanvasRenderingContext2D;
  private timeoutId: number | NodeJS.Timeout = 0;
  private callbackManager: CallbackManager;
  private rightArrowDiv!: HTMLDivElement;
  private debounceResize: DebouncedResizeObserver;
  private menuRenderer: MenuRenderer;
  private drawnLine: DrawnLineRenderer;
  overlayCanvas: HTMLCanvasElement;

  RegisterCallback(action: CallbackAction, callback: (data: CallbackData) => void) {
    this.callbackManager.registerCallback(action, callback);
  }

  RemoveCallback(action: CallbackAction, callbackToRemove: (data: CallbackData) => void) {
    this.callbackManager.removeCallback(action, callbackToRemove);
  }

  SetLines(lines: LineData[]): void {
    this.drawnLine.setLines(lines);
    this.RenderOverlays();
  }

  //TODO: why are there two of these
  public DebounceResize(): void {
    this.debounce(() => {
      this.resizeCanvas();
    }, 250); // 250 ms debounce time
  }

  //TODO, is this even right?
  private debounce = (func: Function, wait: number): void => {
    clearTimeout(this.timeoutId);
    this.timeoutId = setTimeout(() => {
      func();
    }, wait);
  };

  private resizeCanvas(): void {
    this.xHelper.UpdateCanvasWidth(this.parentDiv.clientWidth);
    this.overlayCanvas.width = this.parentDiv.clientWidth;
    this.overlayCanvas.height = this.parentDiv.clientHeight;
    this.Areas.forEach((area) => {
      area.canvas.width = this.parentDiv.clientWidth;
      area.canvas.height = this.parentDiv.clientHeight;
    });
    this.RenderAll();
  }

  GetChartArea = (mousePoint: Point): Area | null => {
    for (let index = 0; index < this.Areas.length; index++) {
      const area = this.Areas[index];
      if (area.yHelper.IsYInArea(mousePoint.y)) {
        return area;
      }
    }
    return null;
  };

  AddArea = (heightPercent: number): Area => {
    var area = new Area(heightPercent / 100, this.parentDiv, this.xHelper);
    this.Areas.push(area);
    return area;
  };

  SetData = (data: ChartDataTypes) => {
    if (Array.isArray(data[0])) {
      var index = 0;
      this.Areas.forEach((area) => {
        area.plots.forEach((plot) => {
          plot.data.SetData(data[index] as PointTypes[]);
          index += 1;
        });
      });
      this.xHelper.keys = data[0].map((x) => x.Date);
    } else {
      this.xHelper.keys = (data as PointTypes[]).map((x) => x.Date);
      this.Areas[0].plots[0].data.SetData(data as PointTypes[]);
    }

    this.RenderAll();
  };

  RenderOverlays = () => {
    this.overlayModules.forEach((module) => {
      module.render();
    });
  };

  UpdateXAspects = (): void => {
    this.xHelper.UpdateXAspects();
    this.rightArrowDiv.style.display = this.xHelper.isShowingLatest ? 'none' : '';
    this.rightArrowDiv.style.left =
      this.xHelper.canvasWidth - 20 - this.xHelper.padding.right / 2 + 'px';
  };

  UpdateYAspects = (): void => {
    let areaPos = 0 + this.xHelper.padding.top;
    this.Areas.forEach((area) => {
      area.UpdateYAspects();
      area.yHelper.areaProps.topLeft = {
        x: this.xHelper.padding.left,
        y: areaPos,
      };
      areaPos = area.yHelper.areaProps.topLeft.y + area.yHelper.areaHeight;
    });
  };

  UpdateChartAspects = () => {
    this.UpdateXAspects();
    this.UpdateYAspects();
  };

  RenderAll = () => {
    this.UpdateChartAspects();
    this.RenderOverlays();
    this.Areas.forEach((area) => {
      area.RenderPlotModules();
    });
  };

  // New destroy method
  public destroy(): void {
    // Remove event listeners
    this.parentDiv.removeEventListener('mouseleave', this.handleMouseLeave);

    // Cleanup `rightArrowDiv` if it was created
    if (this.rightArrowDiv && this.rightArrowDiv.parentElement) {
      this.rightArrowDiv.parentElement.removeChild(this.rightArrowDiv);
    }

    // Cleanup `overlayCanvas` and `overlayCtx`
    if (this.overlayCanvas) {
      this.overlayCanvas.remove();
    }

    // Clear `timeoutId`
    clearTimeout(this.timeoutId);

    this.debounceResize.disconnect();

    //TODO: make sure children get cleaned up?
    this.overlayModules.forEach((mod) => {
      mod.destroy && mod.destroy();
    });

    this.menuRenderer.destroy();

    // Clear arrays
    this.Areas = [];
    this.overlayModules = [];
  }
}
