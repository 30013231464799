import React from "react";
import { Link } from "react-router-dom";
import { APPROUTES } from "../appRoutes";
import qullamaggieImage from "../../static_content/kq14_milly.png";
import twentytwentytwo from "../../static_content/kq_2022_14milly.png";
import onefourloss from "../../static_content/kq_1point4_milly_loss.png";
import eighteenOnEighty from "../../static_content/kq_18milly_on_80milly.png";
import { Card, Collapse, Typography } from "@mui/material";
import ReadMoreOutlinedIcon from "@mui/icons-material/ReadMoreOutlined";

import ExitToAppIcon from "@mui/icons-material/ExitToApp";

export const TWEET_TOP15TAXPAYER =
  "https://x.com/Qullamaggie/status/1626667693393731585?lang=en";
export const TOP_SWEDISH_TAX2021 =
  "https://nyheter24.se/nyheter/ekonomi/1118106-de-tjanade-mest-pengar-2021-bianca-ingrosso-yngst-pa-listan";
export const KQ_YOUTUBE = "https://www.youtube.com/qullamaggie";
export const YOUTUBE_ONEFOURLOSS = "https://youtu.be/VtBYmOR83oU?t=3755";
export const KQ_BLOG_FAQ = "https://qullamaggie.com/faq/";

export const WebHome = () => {
  const [checked, setChecked] = React.useState(false);
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Typography variant="body1" gutterBottom>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            maxWidth: "800px",
            gap: "10px",
          }}
        >
          <Card
            style={{
              padding: "5px",
              backgroundColor: "darkgreen",
              minWidth: "116px",
              width: "116px",
              height: "34px",
              minHeight: "34px",
            }}
          >
            <Link to={APPROUTES.APP.ROOT}>
              <span
                style={{
                  display: "flex",
                  minWidth: "106px",
                  alignItems: "center",
                  color: "white",
                }}
              >
                <ExitToAppIcon /> <span>Go to App</span>
              </span>
            </Link>
          </Card>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
            }}
          >
            <Card style={{ padding: "5px" }}>
              <p>View the real time results of the exact scans used by one of the most successful swing traders of all time Qullamaggie.</p>
            </Card>

            <Card style={{ padding: "5px" }}>
              <p>Kristjan Kullamägi, better known by his online handle "Qullamaggie", is likely the most successful trader to make the majority of his profit in the public eye. For ~5 years Kristjan traded his accounts while live streaming the first few hours of his day on twitch and subsequetly uploading the recordings to youtube. In an age where many "traders" on social media, even known profitable ones, make much more money by selling trading services and courses{" "}</p>
              {!checked && (
                <>
                  <p>...</p>
                  <p
                    onClick={() => setChecked(!checked)}
                    style={{
                      cursor: "pointer",
                    }}
                  >
                    <ReadMoreOutlinedIcon /> Read More
                  </p>
                </>
              )}
              <Collapse
                in={checked}
                collapsedSize="100%"
                timeout="auto"
                aria-hidden={!checked}
              >
                <p>
                  , Kristjan never sold a service and has stated he never plans to. We have to do some investigation to prove Kristjan isn't another online scammer like many on social media. He has stated he was a{" "}
                  <a href={TWEET_TOP15TAXPAYER}>top 15 tax payer</a> in sweden in 2020 and 2021. Even though he hasn't produced audited results, tax information in Sweden is public.{" "}
                  <a href={TOP_SWEDISH_TAX2021}>An article</a> of the top tax payers in Sweden from 2021 states he made 360 million kronor or roughly $32 million USD in 2021. Early in his career Kristjan would frequently post gains or losses on social media in the tens of thousands of dollars. His most recent profit post comes from March 8th 2022 showing a staggering $14 million dollar profit on what he later clarified were mostly short positions
                  <p>
                    <a href="https://x.com/Qullamaggie/status/1501250724772171780">
                      <img
                        src={twentytwentytwo}
                        alt="Qullamaggie"
                        style={{ maxWidth: "100%", height: "auto" }}
                      />
                    </a>
                  </p>
                  His previous gain or loss post was from Feb 24th, 2021 also showing combined gains of ~$14 million.
                  <p>
                    <a href="https://x.com/Qullamaggie/status/1364686853576798210">
                      <img
                        src={qullamaggieImage}
                        alt="Qullamaggie"
                        style={{ maxWidth: "100%", height: "auto" }}
                      />
                    </a>
                  </p>
                  <p>
                    A post from his blog during the height of the bull run shows profit of ~$15 million on position value of ~$79 million along with $2.8 million of realized profit.
                    <img
                      src={eighteenOnEighty}
                      alt="Qullamaggie"
                      style={{ maxWidth: "100%", height: "auto" }}
                    />
                  </p>
                  It hasn't all been easy. Kristjan states on his blog that he blew up his tradding accounts 3 times before he finally became a profitable trader. Even after all his success he still has suffered significant losses. One of his most notable which was{" "}
                  <a href={YOUTUBE_ONEFOURLOSS}>captured live on his stream</a>.
                  Kristjan 'fat fingered' (shorted double the shared he intended to) and got caught in multiple halts in which he ended up losing $1.4 million in a matter of minutes.
                  <p>
                    <a href={KQ_BLOG_FAQ}>
                      <img
                        src={onefourloss}
                        alt="Qullamaggie"
                        style={{ maxWidth: "100%", height: "auto" }}
                      />
                    </a>
                  </p>
                </p>
              </Collapse>
            </Card>
          </div>
        </div>
      </Typography>
    </div>
  );
};
