import axios, {
  AxiosInstance,
  AxiosError,
  InternalAxiosRequestConfig,
} from "axios";
import eventEmitter, { EVENTS } from "../emitter/eventEmitter";
import { useSnackStore } from "../store";

// Flag to prevent multiple refresh token requests
let isRefreshing = false;
let refreshSubscribers: ((token: string) => void)[] = [];

// Custom interface for request config with _retry property
interface CustomAxiosRequestConfig extends InternalAxiosRequestConfig {
  _retry?: boolean;
}

// Function to add callbacks to array
const subscribeTokenRefresh = (cb: (token: string) => void) => {
  refreshSubscribers.push(cb);
};

// Function to execute callbacks with new token
const onTokenRefreshed = (token: string) => {
  refreshSubscribers.forEach((cb) => cb(token));
  refreshSubscribers = [];
};

const getAxiosInstance = () => {
  var ax: AxiosInstance;

  ax = axios.create({
    baseURL: import.meta.env.VITE_API_ROOT_URL || 'http://localhost:5193/api',
  });

  // Add a request interceptor to attach the token
  ax.interceptors.request.use(
    (config) => {
      const token = localStorage.getItem("token");
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  // Add a response interceptor to handle 401 errors
  ax.interceptors.response.use(
    (response) => response,
    async (error: AxiosError) => {
      const originalRequest = error.config as CustomAxiosRequestConfig;

      if (!originalRequest) {
        return Promise.reject(error);
      }

      // Check if error is 401 and it's not a refresh token request
      if (error.response?.status === 401 && !originalRequest._retry) {
        if (isRefreshing) {
          // If already refreshing, wait for new token
          try {
            const token = await new Promise<string>((resolve) => {
              subscribeTokenRefresh((token: string) => {
                resolve(token);
              });
            });
            originalRequest.headers.Authorization = `Bearer ${token}`;
            return ax(originalRequest);
          } catch (err) {
            return Promise.reject(err);
          }
        }

        originalRequest._retry = true;
        isRefreshing = true;

        try {
          const refreshToken = localStorage.getItem("refreshToken");
          if (!refreshToken) {
            throw new Error("No refresh token available");
          }

          // Call refresh token endpoint
          const response = await ax.post("/Auth/refresh", {
            token: localStorage.getItem("token"),
            refreshToken: refreshToken,
          });

          const { token, refreshToken: newRefreshToken } = response.data;

          // Update stored tokens
          localStorage.setItem("token", token);
          localStorage.setItem("refreshToken", newRefreshToken);

          // Update Authorization header
          ax.defaults.headers.common["Authorization"] = `Bearer ${token}`;
          originalRequest.headers.Authorization = `Bearer ${token}`;

          // Notify all subscribers about new token
          onTokenRefreshed(token);

          isRefreshing = false;

          // Retry original request
          return ax(originalRequest);
        } catch (refreshError) {
          isRefreshing = false;
          useSnackStore
            .getState()
            .SetSnackMessage(
              "Session expired. Please log in again.",
              "warning"
            );
          eventEmitter.emit(EVENTS.LOGOUT);
          return Promise.reject(refreshError);
        }
      }

      if (error.response?.status !== 401) {
        useSnackStore
          .getState()
          .SetSnackMessage("An error has occurred", "error");
      }

      return Promise.reject(error);
    }
  );

  return ax;
};

export { axios, getAxiosInstance };

export default getAxiosInstance();
